import * as React from 'react'
import { MailOutlined } from '@ant-design/icons'
import { media, Capital, H1, Body, HSpaced, Block } from '@thesisedu/web/dist'
import styled from 'styled-components'
import { BorderInput, Container, GlowButton, LargeH1, Subtitle } from '../components/styled'
import { PageHero } from '../components/PageHero'
import { FRONTEND } from '../constants'
import { SupportWidget } from '../components/support/SupportWidget'
import { FeaturedTestimonial } from '../components/testimonials/FeaturedTestimonial'
import { TestimonialsList } from '../components/testimonials/TestimonialsList'
import { SEO } from '../components/SEO'
import { ScheduleDemoEmail } from '../components/ScheduleDemoEmail'

const TestimonialsContent: React.FC = () => {
  const [email, setEmail] = React.useState('')
  return (
    <>
      <Container>
        <SEO title={'Testimonials'} />
        <PageHero
          rightContent={
            <RightContainer>
              <FeaturedTestimonial />
            </RightContainer>
          }
        >
          <LargeH1>A word from our 2,000+ educators.</LargeH1>
          <Subtitle>
            Prodigies Academy's platform is constantly improving as we release new features
            requested by our teachers and students every week.
          </Subtitle>
          <Block marginTop={'@size-l'} marginBottom={'@size-s'}>
            <BorderInput
              prefix={<MailOutlined />}
              placeholder={'Enter your email address'}
              size={'large'}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Block>
          <HSpaced align={'center'} space={'@size-m'}>
            <GlowButton
              size={'large'}
              type={'primary'}
              onClick={() => {
                window.location.href = `${FRONTEND}/auth/trial-request${
                  email ? `?email=${encodeURIComponent(email)}` : ''
                }`
              }}
            >
              &nbsp;&nbsp;Start your Free Trial&nbsp;&nbsp;
            </GlowButton>
            <Body color={'@text-color-secondary'}>30-day trial. Full access.</Body>
          </HSpaced>
        </PageHero>
        <Block marginTop={'@size-section-md'}>
          <div style={{ textAlign: 'center' }}>
            <Capital isBlock color={'@primary-color'}>
              Teacher Stories
            </Capital>
            <H1 isBlock>Don't just take our word for it.</H1>
          </div>
          <TestimonialsList />
        </Block>
        <Block marginTop={'@size-section-md'}>
          <ScheduleDemoEmail />
        </Block>
        <Block marginTop={'@size-section-md'}>
          <SupportWidget />
        </Block>
      </Container>
    </>
  )
}

const RightContainer = styled.div`
  margin-left: ${(props) => props.theme['@size-s']};
  min-width: 300px;
  display: none;
  ${media.lg} {
    display: block;
    margin-left: ${(props) => props.theme['@size-l']};
  }
  ${media.xl} {
    margin-left: ${(props) => props.theme['@size-section-sm']};
  }
`

const TestimonialsPage: React.FC = () => <TestimonialsContent />

export default TestimonialsPage
